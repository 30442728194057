import { MeetingCard } from "@/components/cards/MeetingCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import type { Meeting } from "@/types/meeting";

interface MeetingsCarouselProps {
  meetings: Meeting[];
}

export const MeetingsCarousel = ({ meetings }: MeetingsCarouselProps) => {
  return (
    <div className="p-5">
      {meetings.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No upcoming meetings</p>
        </div>
      ) : (
        <Carousel>
          <CarouselContent className="flex gap-3">
            {meetings.map((meeting) => (
              <CarouselItem
                onClick={() =>
                  (window.location.href = `/meetings/${meeting.id}`)
                }
                key={meeting.id}
                className="h-[350px] w-full max-w-sm flex-shrink-0 flex-grow-0 basis-1/3"
              >
                <MeetingCard meeting={meeting} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
