import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Logo } from "@/components";
import { EmployeeCombobox } from "@/components/comboboxes/employeeCombobox";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import type { Employee } from "@/types/employee";

export const Login = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  // Used to store the currently selected employee
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );

  // Fetch the list of employees
  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<{ data: Employee[] }> =>
      fetch(`/api/users/`).then((res) => res.json()),
    queryKey: ["users"],
  });

  if (isLoading) {
    return (
      <div data-testid="loading">
        <LoadingScreen data-testid="loading" />
      </div>
    );
  }

  if (error) {
    return <div className="error">Error: error fetching</div>;
  }

  const users = response?.data ?? [];

  // Sets the selected employee
  const handleEmployeeSelect = (employee: Employee | null) => {
    if (employee) {
      setSelectedEmployee(employee);
    }
  };

  // Saves the selected employee details in session storage
  const handleLogIn = () => {
    if (selectedEmployee) {
      setUser({
        id: selectedEmployee.id,
        email: selectedEmployee.email,
        name: selectedEmployee.name,
        picture: selectedEmployee.avatarUrl,
        role: "signedIn",
      });
      navigate("/home");
    }
  };

  return (
    <div className="flex h-screen grow flex-col items-center justify-center gap-9 bg-gray-800 px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo className="mx-auto h-16 w-auto" />

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Sign in to your account
        </h2>
      </div>
      {/* Combobox to select an employee */}
      <EmployeeCombobox employees={users} onSelect={handleEmployeeSelect} />

      {/* Sign-in button that triggers the login process */}
      <button
        type="button"
        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        disabled={!selectedEmployee} // Disable the button if no employee is selected
        onClick={handleLogIn}
      >
        Sign in
      </button>
    </div>
  );
};
