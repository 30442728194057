import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { ActionItem } from "@/types/action_item";
import ActionItemCard from "../cards/ActionItemCard";

interface ActionItemCarouselProps {
  actionItems: ActionItem[];
}

const ActionItemCarousel = ({ actionItems }: ActionItemCarouselProps) => {
  return (
    <Carousel
      className="relative mx-auto w-full px-0 py-5 lg:px-5"
      opts={{ loop: false }}
    >
      <CarouselPrevious />
      <CarouselContent className="flex">
        {actionItems.map((actionItem) => (
          <CarouselItem
            key={actionItem.id}
            className="sm:basis-1/2 xl:basis-1/3"
          >
            <ActionItemCard
              title={actionItem.title}
              description={actionItem.description}
              due_date={actionItem.due_date}
              assigned_id={actionItem.assigned.toString()}
              completed={actionItem.completed}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselNext />
    </Carousel>
  );
};
export default ActionItemCarousel;
