import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { MeetingsCarousel } from "@/components/carousels/MeetingsCarousel";
import { NotesCarousel } from "@/components/carousels/NotesCarousel";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import type { Employee } from "@/types/employee";
import type { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";
import { combineNotesAndMeetings } from "@/utils/notesWithMeetings";

interface UserData extends Employee {
  notes: MeetingNote[];
  meetings: Meeting[];
}

export const EmployeeDetails = () => {
  const { user } = useUserStore();
  const { id } = useParams();

  const {
    data: employee,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userNotes", id],
    queryFn: (): Promise<UserData> =>
      fetch(`/api/users/${id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!id,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !employee) {
    return <p>Error fetching employee data</p>;
  }

  const employee_notes = combineNotesAndMeetings(employee!);

  return (
    <div className="p-10 text-white">
      <div className="prose mb-4 flex items-center gap-4">
        <img
          className="h-[125px] w-[125px] rounded-full object-cover shadow-lg"
          src={employee.avatarUrl ?? ""}
          alt="user profile"
        />
        <div className="flex flex-col">
          <h1 className="mb-0 text-white">{employee.name ?? "Name"}</h1>
          <p className="not-prose text-white">{employee.email ?? "Mail"}</p>
          <p className="not-prose text-white">
            {employee.lastPosition ?? "Description"}
          </p>
        </div>
      </div>
      <div className="p-5">
        <h2 className="relative text-2xl font-bold text-white">Meetings</h2>
      </div>
      <MeetingsCarousel meetings={employee.meetings} />
      <div className="ml-5 mt-5">
        <h2 className="relative text-2xl font-bold text-white">Notes</h2>
      </div>
      <NotesCarousel notes={employee_notes} />
    </div>
  );
};
