import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { NotesCarousel } from "@/components/carousels/NotesCarousel";
import LoadingScreen from "@/components/LoadingScreen";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import type { Employee } from "@/types/employee";
import type { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";
import { splitNotesByVisibility } from "@/utils/notesUtilities";
import { combineNotesAndMeetings } from "@/utils/notesWithMeetings";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

interface UserData extends Employee {
  notes: MeetingNote[];
  meetings: Meeting[];
}

export const Notes = () => {
  const { user } = useUserStore();
  const userId = user!.id;

  const {
    data: userData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userNotes", userId],
    queryFn: (): Promise<UserData> =>
      fetch(`/api/users/${userId}`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!userId,
  });

  const notesWithMeetings = useMemo(() => {
    return combineNotesAndMeetings(userData!);
  }, [userData]);

  const [publicNotes, privateNotes] = useMemo(() => {
    return splitNotesByVisibility(notesWithMeetings);
  }, [notesWithMeetings]);

  if (isLoading) {
    return (
      <div data-testid="loading">
        {" "}
        <LoadingScreen />
      </div>
    );
  }

  if (error || !userData) {
    return <ErrorBoundaryFallback message="Error fetching data" />;
  }
  return (
    <div className="p-10">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          Public notes
        </h1>
        <Button
          variant="outline"
          size="icon"
          className="ml-auto text-xl text-white"
          onClick={() => (window.location.href = "/notes/new")}
          aria-label="Create a new note"
        >
          +
        </Button>
      </div>
      {publicNotes.length > 0 ? (
        <NotesCarousel notes={publicNotes} />
      ) : (
        <p className="text-slate-400">No public notes available</p>
      )}

      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          Private notes
        </h1>
      </div>
      {privateNotes.length > 0 ? (
        <NotesCarousel notes={privateNotes} />
      ) : (
        <p className="text-slate-400">No private notes available</p>
      )}
    </div>
  );
};
