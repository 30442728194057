import * as React from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import type { Employee } from "@/types/employee";

interface ComboboxProps {
  employees: Employee[];
  onSelect: (selectedEmployee: Employee | null) => void;
}

const getInitials = (name: string | undefined) => {
  if (name) {
    const names = name.split(" ");
    if (names[0] && names[1]) {
      const initials =
        names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
      return initials;
    }
  }
};

export function EmployeeCombobox({ employees, onSelect }: ComboboxProps) {
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<Employee | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const comboboxRef = React.useRef<HTMLDivElement>(null);

  const handleSelect = (employee: Employee) => {
    setSelectedEmployee(employee);
    onSelect(employee);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      comboboxRef.current &&
      !comboboxRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full max-w-[17rem]" ref={comboboxRef}>
      <div
        className="flex h-14 w-full cursor-pointer items-center justify-between rounded-md border border-neutrals-medium-300 bg-white p-2 text-sm text-neutrals-dark-500 shadow-md hover:border-primary-dark-500 focus:border-primary-dark-600 focus:outline-none focus:ring-1 focus:ring-primary-dark-500"
        onClick={() => setIsOpen(!isOpen)}
        data-testid="select"
      >
        {selectedEmployee ? (
          <div className="flex items-center">
            <Avatar className="h-9 w-9">
              <AvatarImage src={selectedEmployee.avatarUrl} />
              <AvatarFallback>
                {getInitials(selectedEmployee.name)}
              </AvatarFallback>
            </Avatar>
            <div className="pl-2">
              <strong>{selectedEmployee.name}</strong>
              <div className="text-xs text-gray-500">
                {selectedEmployee.email}
              </div>
            </div>
          </div>
        ) : (
          <span className="text-gray-500">Select employee...</span>
        )}
      </div>
      {isOpen && (
        <ul className="absolute z-10 mt-1 max-h-[35vh] w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg focus:outline-none">
          {employees.map((employee) => (
            <li
              key={employee.id}
              className="hover:bg-primary-light-100 flex h-14 cursor-pointer select-none items-center p-2"
              onClick={() => handleSelect(employee)}
            >
              <Avatar className="h-9 w-9">
                <AvatarImage src={employee.avatarUrl} />
                <AvatarFallback>{getInitials(employee.name)}</AvatarFallback>
              </Avatar>
              <div className="p-2">
                <strong>{employee.name}</strong>
                <div className="text-xs text-gray-500">{employee.email}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
