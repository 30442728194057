import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/ui";

interface ActionItemCardProps {
  title: string;
  description: string;
  due_date: string;
  assigned_id: string;
  completed: boolean;
}

const ActionItemCard = ({
  title,
  description,
  due_date,
  assigned_id,
  completed,
}: ActionItemCardProps) => {
  return (
    <Card className="flex h-[300px] w-full flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
      {/* Header section */}
      <CardHeader className="flex flex-col items-center bg-black/50 pb-2 pt-4">
        {/* Fixed height for the header */}
        <CardTitle className="text-white">{title}</CardTitle>
        <h1
          className={`w-full text-center text-sm font-bold ${completed ? "text-green-400" : "text-yellow-400"}`}
        >
          {completed ? "Completed" : "Unfinished"}
        </h1>
      </CardHeader>
      {/* Content section */}
      <CardContent className="mt-2 flex-1 pb-0 text-left">
        {/* Allow overflow and limit height */}
        <strong>Description:</strong>
        <div className="mb-2 max-h-32 overflow-y-auto pr-2">
          <h1 className="text-left text-justify text-gray-300">
            {description}
          </h1>
        </div>
        <div className="mb-2 flex">
          <strong>Assigned To:</strong>
          <h1 className="ml-1 text-left text-gray-300">{assigned_id}</h1>
        </div>
        <div className="mb-2 flex">
          <strong>Due Date:</strong>
          <h1 className="ml-1 text-left text-gray-300">
            {due_date ? due_date : "N/A"}
          </h1>
        </div>
      </CardContent>
      {/* Footer section */}
      <CardFooter className="flex flex-col items-center pb-2">
        <Button className="group items-center rounded-md bg-transparent p-2 text-sm font-semibold text-gray-400 hover:bg-gray-900 hover:text-white focus:ring-0">
          {completed ? "Reopen Item" : "Mark as Completed"}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ActionItemCard;
