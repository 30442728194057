import { useState } from "react";
import { format } from "date-fns";

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { formatParticipantList } from "@/utils/formatParticipantList";

interface MeetingsTableProps {
  meetings: Meeting[];
}

export const MeetingsTable = ({ meetings }: MeetingsTableProps) => {
  const { user } = useUserStore();
  const itemsPerPage = 4;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);

  return (
    <>
      {meetings.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No past meetings</p>
        </div>
      ) : (
        <div className="rounded-md bg-gray-800 text-white">
          <Table>
            <TableHeader className="bg-gray-900">
              <TableRow className="hover:bg-gray-900">
                <TableHead className="font-bold text-white">
                  Meeting Type
                </TableHead>
                <TableHead className="font-bold text-white">
                  Participants
                </TableHead>
                <TableHead className="font-bold text-white">Location</TableHead>
                <TableHead className="font-bold text-white">Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-lg">
              {meetings.slice(startIndex, endIndex).map((meeting) => (
                <TableRow
                  data-testid={`meeting-row${meeting.id}`}
                  onClick={() =>
                    (window.location.href = `/meetings/${meeting.id}`)
                  }
                  key={meeting.id}
                  className="cursor-pointer hover:bg-slate-700"
                >
                  <TableCell>{meeting.meeting_type}</TableCell>
                  <TableCell>
                    {!meeting.participants ? (
                      <p> No participants </p>
                    ) : (
                      formatParticipantList(
                        meeting.participants.map((p) => p.name),
                        user!.name,
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {meeting.location
                      ? meeting.location
                      : "No meeting location"}
                  </TableCell>
                  <TableCell>
                    {format(
                      new Date(meeting.meeting_date),
                      "EEEE, d MMMM, yyyy",
                    )}
                    <br />
                    <small>
                      {format(new Date(meeting.meeting_date), "h:mm a")}
                    </small>
                    <br />
                    <small>({meeting.relative_time})</small>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="mt-4 flex justify-center">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    data-testid="pagination-previous"
                    className={
                      startIndex === 0
                        ? "pointer-events-none opacity-50"
                        : "cursor-pointer"
                    }
                    onClick={() => {
                      setStartIndex(startIndex - itemsPerPage);
                      setEndIndex(endIndex - itemsPerPage);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    data-testid="pagination-next"
                    className={
                      endIndex >= meetings.length
                        ? "pointer-events-none opacity-50"
                        : "cursor-pointer"
                    }
                    onClick={() => {
                      setStartIndex(startIndex + itemsPerPage);
                      setEndIndex(endIndex + itemsPerPage);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      )}
    </>
  );
};
