import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface NotesProfileCardProps {
  id: number;
  writer: number;
  description: string;
  private_note: boolean;
  created_at: Date | null;
  updated_at: Date | null;
}

export const NoteMeetingCard = ({
  id,
  writer,
  description,
  private_note,
  created_at,
  updated_at,
}: NotesProfileCardProps) => {
  return (
    <a href={`/notes/${id}`}>
      <Card className="flex h-80 w-full flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
        {/* Header section */}
        <CardHeader className="flex flex-col items-center bg-black/50 pb-2 pt-4">
          {/* Fixed height for the header */}
          <CardTitle className="text-white">
            {private_note ? "Private Note" : "Public Note"}
          </CardTitle>
          <h1 className="text-sm text-gray-400">by {writer}</h1>
        </CardHeader>

        {/* Content section */}
        <CardContent className="my-2 flex-1 text-left">
          {/* Allow overflow */}

          <strong>Description: </strong>
          <p>{description}</p>
        </CardContent>

        {/* Footer section */}
        <CardFooter className="flex flex-col items-center pb-2">
          <p className="text-sm text-gray-400">
            {updated_at
              ? `Updated at: ${new Date(updated_at).toLocaleDateString()}`
              : `Created at: ${new Date(created_at!).toLocaleDateString()}`}
          </p>
        </CardFooter>
      </Card>
    </a>
  );
};

export default NoteMeetingCard;
