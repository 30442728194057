import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { MultiSelect } from "@/components/ui/multi-select";

interface Option {
  value: string;
  label: string;
  icon?: React.ComponentType<{ className?: string }>;
}

interface MultiSelectInputProps<TFieldValues extends FieldValues, TContext> {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label?: string;
  placeholder?: string;
  options: Option[];
  defaultValue?: string[];
  className?: string;
}

const MultiSelectInput = <TFieldValues extends FieldValues, TContext>({
  control,
  name,
  label,
  placeholder = "Select options",
  options,
  defaultValue = [],
  className,
}: MultiSelectInputProps<TFieldValues, TContext>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          {label && <FormLabel className="text-white">{label}</FormLabel>}
          <FormControl>
            <MultiSelect
              options={options}
              onValueChange={(value) => field.onChange(value)}
              defaultValue={defaultValue}
              placeholder={placeholder}
              className={className}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default MultiSelectInput;
