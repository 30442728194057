import { format } from "date-fns";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { Label } from "@/ui";

interface NotesProfileCardProps {
  id: number;
  type: string;
  writer: string;
  description: string;
  private_meeting: boolean;
  created_at: Date | null;
  updated_at: Date | null;
  meeting: Meeting;
}

export const NotesProfileCard = ({
  id,
  type,
  writer,
  description,
  private_meeting,
  created_at,
  updated_at,
  meeting,
}: NotesProfileCardProps) => {
  const { user } = useUserStore();
  return (
    <a href={`/notes/${id}`}>
      <Card className="flex h-full flex-col items-center justify-center border-none bg-gray-800 text-center text-white hover:bg-slate-700">
        <CardHeader className="flex flex-col items-center pb-4">
          <CardTitle className="mb-1 text-2xl font-bold text-white">
            {type} - {meeting.target}
          </CardTitle>

          <p className="text-lg text-gray-400">
            {created_at
              ? format(new Date(created_at), "d MMMM, yyyy")
              : "Unknown Date"}
          </p>
          <p className="text-md text-gray-500">
            {created_at
              ? format(new Date(created_at), "h:mm a")
              : "Unknown Time"}
          </p>
        </CardHeader>
        <CardContent className="flex flex-col items-center">
          <CardDescription className="text-lg text-white">
            {description}
          </CardDescription>
          <Label
            label={private_meeting ? "Private note" : "Public note"}
            containerClassName="mt-2"
            className={`text-center text-sm ${private_meeting ? "text-purple-400" : "text-green-400"}`}
          />
        </CardContent>
        <CardFooter className="flex flex-col items-center">
          <p className="text-sm text-gray-400">
            Written by: {writer === user!.name ? "You" : writer}
          </p>
          <p className="text-sm text-gray-400">
            {updated_at
              ? `Updated at: ${format(new Date(updated_at), "dd/MM/yyyy")}`
              : "Not updated yet"}
          </p>
        </CardFooter>
      </Card>
    </a>
  );
};
