import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";

import { MeetingsCarousel } from "@/components/carousels/MeetingsCarousel";
import LoadingScreen from "@/components/LoadingScreen";
import { MeetingsTable } from "@/components/tables/MeetingsTable";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const Meetings = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const now = useMemo(() => new Date(), []);

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["meetings", userId],
    queryFn: (): Promise<{ data: Meeting[] }> =>
      fetch(`/api/meetings`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!userId,
  });
  const meetings: Meeting[] = useMemo(() => response?.data ?? [], [response]);

  const pastMeetings = useMemo(() => {
    return meetings
      .filter((meeting) => new Date(meeting.meeting_date) < now)
      .sort(
        (a, b) =>
          new Date(b.meeting_date).getTime() -
          new Date(a.meeting_date).getTime(),
      )
      .map((meeting) => ({
        ...meeting,
        relative_time: formatDistanceToNow(new Date(meeting.meeting_date), {
          addSuffix: true,
        }),
      }));
  }, [meetings, now]);

  const upcomingMeetings = useMemo(() => {
    return meetings
      .filter((meeting) => new Date(meeting.meeting_date) >= now)
      .sort(
        (a, b) =>
          new Date(a.meeting_date).getTime() -
          new Date(b.meeting_date).getTime(),
      )
      .map((meeting) => ({
        ...meeting,
        relative_time: formatDistanceToNow(new Date(meeting.meeting_date), {
          addSuffix: true,
        }),
      }));
  }, [meetings, now]);

  if (isLoading) {
    return (
      <div data-testid="loading">
        {" "}
        <LoadingScreen />
      </div>
    );
  }

  if (error) {
    return <ErrorBoundaryFallback message="Error fetching data" />;
  }

  return (
    <div className="p-10">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          Upcoming meetings
        </h1>
        <Button
          variant="outline"
          size="icon"
          className="ml-auto text-xl text-white"
          onClick={() => (window.location.href = "/meetings/new")}
          aria-label="Create a new meeting"
        >
          +
        </Button>
      </div>
      <MeetingsCarousel meetings={upcomingMeetings} />
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          Past meetings
        </h1>
      </div>
      <MeetingsTable meetings={pastMeetings} />
    </div>
  );
};
