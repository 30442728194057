export const ROUTES = {
  base: "/",
  login: "/login",
  home: "/home",
  employeeList: "/employees",
  userProfile: "/profile",
  employeeId: "/employees/:id",
  createNote: "/notes/new",
  meetingId: "/meetings/:id",
  createMeeting: "/meetings/new",
  notFound: "*",
  meetings: "/meetings",
  notes: "/notes",
  noteId: "/notes/:id",
} as const;
export const MODAL_ROUTES = {
  exampleModal: "/example-modal",
  userForm: "/user-form",
  notesModal: "/notes-modal",
} as const;
