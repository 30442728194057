import { useQuery } from "@tanstack/react-query";

import LoadingScreen from "@/components/LoadingScreen";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { Employee } from "@/types/employee";

export const EmployeeList = () => {
  const { user } = useUserStore();

  const email = user!.email;

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<{ data: Employee[] }> =>
      fetch(`/api/users/${email}/employees`).then((res) => res.json()),
    queryKey: ["employees"],
  });

  if (isLoading) {
    return (
      <div data-testid="loading">
        {" "}
        <LoadingScreen />
      </div>
    );
  }

  // to handle error
  if (error) {
    return (
      <div className="error" data-testid={"fetch-error"}>
        Error: error fetching
      </div>
    );
  }

  const employees = response?.data ?? [];

  return (
    <div className="grid grid-cols-4 gap-8 p-8">
      {employees.map((employee) => (
        <Card className="" key={employee.id}>
          <a href={`/employees/${employee.id}`}>
            <CardHeader className="items-center">
              <Avatar
                className="h-28 w-28"
                data-testid={`avatar-image-${employee.id}`}
              >
                <AvatarImage src={employee.avatarUrl} />
                <AvatarFallback>{employee.name} avatar</AvatarFallback>
              </Avatar>
            </CardHeader>
            <CardContent className="text-center">
              <CardTitle className="text-nostalgia-purple-800">
                {employee.name}
              </CardTitle>
              <CardDescription className="hidden md:block">
                {employee.email}
              </CardDescription>
            </CardContent>
          </a>
        </Card>
      ))}
    </div>
  );
};
