import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import NewNoteForm from "@/components/forms/NewNoteForm";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import { Meeting } from "@/types/meeting";

const CreateNoteScreen = () => {
  const { user } = useUserStore();

  const {
    data: meetings,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<Meeting[]> =>
      fetch("/api/meetings", {
        method: "GET",
        headers: {
          token: user!.id.toString(),
        },
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["meetings"],
  });

  //Access the query parameters
  const location = useLocation(); //Access the current URL
  const queryParams = new URLSearchParams(location.search);
  const meetingQueryParam = queryParams.get("meeting");
  const privateQueryParam = queryParams.get("private");

  //Set the default meeting id
  const defaultMeetingId = useMemo(() => {
    if (!meetingQueryParam) return undefined;

    return meetings?.some(
      (meeting) => meeting.id == parseInt(meetingQueryParam, 10),
    )
      ? meetingQueryParam
      : undefined;
  }, [meetingQueryParam, meetings]);

  //Set the default private value
  const defaultPrivate = privateQueryParam === "false" ? "false" : "true";

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div>Error: error fetching</div>;
  }

  if (!meetings?.length) {
    return <div>No meetings found</div>;
  }

  return (
    <div className="space-y-5 p-8 md:p-24">
      <NewNoteForm
        meetings={meetings}
        defaultValues={{
          meeting_id: defaultMeetingId,
          private: defaultPrivate,
        }}
        writerId={user!.id}
      />
    </div>
  );
};

export default CreateNoteScreen;
