export const formatParticipantList = (
  participants: string[] | undefined,
  toReplace: string,
): string => {
  if (!participants || participants.length === 0) {
    return "No participants";
  }

  const filteredParticipants = participants.filter(
    (participant) => participant !== toReplace,
  );

  const replaced = participants.includes(toReplace)
    ? ["You", ...filteredParticipants]
    : filteredParticipants;

  const result =
    replaced.length > 4 ? [...replaced.slice(0, 4), "..."] : replaced;

  return result.join(", ");
};
