import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import EditNoteForm from "@/components/forms/EditNoteForm";
import LoadingScreen from "@/components/LoadingScreen";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const NoteDetails = () => {
  const { id } = useParams();
  const { user } = useUserStore();
  const writerId = user!.id;
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    data: noteResponse,
    isLoading: isNoteLoading,
    error: noteError,
  } = useQuery({
    queryKey: ["note", id],
    queryFn: (): Promise<{ data: MeetingNote }> =>
      fetch(`/api/notes/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  const {
    data: meetingsResponse,
    isLoading: isMeetingsLoading,
    error: meetingsError,
  } = useQuery({
    queryKey: ["meetings"],
    queryFn: (): Promise<{ data: Meeting[] }> =>
      fetch("/api/meetings", {
        method: "GET",
        headers: {
          token: user!.id.toString(),
        },
      }).then((res) => res.json()),
  });

  if (isNoteLoading || isMeetingsLoading) {
    return <LoadingScreen />;
  }

  if (noteError || meetingsError) {
    return (
      <ErrorBoundaryFallback message="Error fetching note or meetings data." />
    );
  }

  const note: MeetingNote = noteResponse!.data ?? [];
  const meetings: Meeting[] = meetingsResponse!.data ?? [];

  const meeting: Meeting | undefined = meetings.find(
    (meeting) => meeting.id == note.meeting_id,
  );

  if (!meeting) {
    return <ErrorBoundaryFallback message="Meeting not found for the note." />;
  }

  const toggleEditMode = () => setIsEditMode((prevMode) => !prevMode);

  return (
    <div className="p-10">
      {isEditMode ? (
        <div>
          <h1 className="mb-4 text-2xl font-bold text-white">Edit Note</h1>
          <EditNoteForm
            note={note}
            meeting={meeting}
            meetings={meetings}
            writerId={writerId}
            noteId={id!}
            toggleEditMode={toggleEditMode}
          />
        </div>
      ) : (
        <div className="text-white">
          <div className="mb-6 flex items-center justify-between">
            {meeting === undefined ? (
              <h1 className="text-3xl font-bold">
                No meeting has been fetched
              </h1>
            ) : (
              <h1 className="text-3xl font-bold">
                {formatMeetingType(meeting.meeting_type)} - {meeting.target} -{" "}
                {format(new Date(meeting.meeting_date), "EEEE, d MMMM, yyyy")}
              </h1>
            )}
            {note.writer == user!.id.toString() ? (
              <Button
                className="ml-auto text-white"
                variant="outline"
                onClick={toggleEditMode}
              >
                Edit
              </Button>
            ) : null}
          </div>
          <h2 className="text-xl font-bold">Description</h2>
          <p className="mb-4 text-lg">{note.description}</p>
          <h2 className="text-xl font-bold">Content</h2>
          <p className="mb-4 text-sm">{note.content}</p>
          <p
            className={`flex justify-center text-lg font-bold ${note.private ? "text-purple-500" : "text-green-500"}`}
          >
            {note.private ? "Private" : "Public"}
          </p>
        </div>
      )}
    </div>
  );
};
