import { format } from "date-fns";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { formatParticipantList } from "@/utils/formatParticipantList";

export const MeetingCard = ({ meeting }: { meeting: Meeting }) => {
  const { user } = useUserStore();
  return (
    <Card className="flex h-full cursor-pointer flex-col justify-between border border-gray-800 bg-gray-800 p-5 text-center hover:bg-slate-700">
      <CardHeader className="flex flex-col items-center">
        <CardTitle className="text-xl font-bold text-white">
          {meeting.meeting_type} {meeting.relative_time}
        </CardTitle>
        <h1 className="mt-1 text-sm text-gray-400">Target: {meeting.target}</h1>
        <h1 className="text-sm text-gray-400">Location: {meeting.location}</h1>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <CardDescription className="text-md text-wrap">
          <p className="text-white">Participants:</p>
          <p className="text-primary-white-700">
            {!meeting.participants ? (
              <p> No participants </p>
            ) : (
              formatParticipantList(
                meeting.participants.map((p) => p.name),
                user!.name,
              )
            )}
          </p>
        </CardDescription>
      </CardContent>

      <CardFooter className="mt-3 flex flex-col items-center">
        <p className="text-md text-gray-400">
          {format(new Date(meeting.meeting_date), "EEEE, d MMMM, yyyy")}
        </p>
        <p className="text-md text-gray-400">
          {format(new Date(meeting.meeting_date), "h:mm a")}
        </p>
      </CardFooter>
    </Card>
  );
};
