import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Topic } from "@/types/topic";
import TopicCard from "../cards/TopicCard";

interface TopicCarouselProps {
  topics: Topic[];
}

const TopicCarousel = ({ topics }: TopicCarouselProps) => {
  return (
    <Carousel
      className="relative w-full px-0 py-5 lg:px-5"
      opts={{ loop: false }}
    >
      <CarouselPrevious />
      <CarouselContent className="flex">
        {topics.map((topic) => (
          <CarouselItem key={topic.id} className="sm:basis-1/2 xl:basis-1/3">
            <TopicCard
              name={topic.name}
              creator_name={topic.created_by.toString()}
              main_topic={topic.main_topic}
              questions={topic.questions}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselNext />
    </Carousel>
  );
};
export default TopicCarousel;
