import { UserIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Logo } from "@/components";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { icons } from "@/ui";
import { tw } from "@/utils";

const navigation = [
  {
    path: ROUTES.home,
    label: "Home",
    icon: <icons.HomeIcon className="w-6" />,
  },
  {
    path: ROUTES.employeeList,
    label: "Employees",
    icon: <icons.UserGroupIcon className="w-6" />,
  },
] as const;

export const Sidebar = ({
  onCloseSidebar,
}: {
  onCloseSidebar?: () => void;
}) => {
  const { pathname: currentPath } = useLocation();

  const { user, clearUser } = useUserStore();

  const navigate = useNavigate();

  const handleSignOut = () => {
    clearUser();
    navigate("/login");
  };

  return (
    <div className="flex h-screen grow flex-col gap-y-12 overflow-y-auto bg-black/50 px-6 ring-1 ring-white/5">
      <div className="mx-auto flex h-16 shrink-0 py-6 pr-2">
        <Logo className="h-11" />
      </div>
      {
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li className="flex-1">
              <ul className="relative -mx-2 h-full space-y-1">
                {navigation.map((item) => (
                  <li key={item.label}>
                    <Link
                      to={item.path}
                      onClick={onCloseSidebar}
                      className={tw(
                        item.path == currentPath
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:bg-gray-800 hover:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                      )}
                    >
                      {item.icon}
                      {item.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    to={ROUTES.notes}
                    onClick={onCloseSidebar}
                    className={tw(
                      ROUTES.notes == currentPath
                        ? "bg-gray-800 text-white"
                        : "text-gray-400 hover:bg-gray-800 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                    )}
                  >
                    <icons.DocumentIcon className="w-6" />
                    Notes
                  </Link>
                </li>
                <li>
                  <Link
                    to={ROUTES.meetings}
                    onClick={onCloseSidebar}
                    className={tw(
                      ROUTES.meetings == currentPath
                        ? "bg-gray-800 text-white"
                        : "text-gray-400 hover:bg-gray-800 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                    )}
                  >
                    <icons.CalendarIcon className="w-6" />
                    Meetings
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/employees/${user!.id}`}
                    onClick={onCloseSidebar}
                    className={tw(
                      `/employees/${user!.id}` == currentPath
                        ? "bg-gray-800 text-white"
                        : "text-gray-400 hover:bg-gray-800 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                    )}
                  >
                    <UserIcon className="w-6" />
                    My profile
                  </Link>
                </li>
                <li className="absolute bottom-0 w-full">
                  <button
                    onClick={() => handleSignOut()}
                    className="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <icons.ArrowLeftOnRectangleIcon className="w-6" />
                    Sign Out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      }
    </div>
  );
};
