import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import ActionItemCarousel from "@/components/carousels/ActionItemCarousel";
import NoteCarousel from "@/components/carousels/MeetingNoteCarousel";
import TopicCarousel from "@/components/carousels/TopicCarousel";
import CancelMeetingForm from "@/components/forms/CancelMeetingForm";
import LoadingScreen from "@/components/LoadingScreen";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useUserStore } from "@/stores";
import { MeetingData } from "@/types/meeting_data";
import { Button } from "@/ui";
import { formatDate } from "@/utils/formatDate";
import { formatMeetingType } from "@/utils/formatMeetingType";

export const MeetingDetails = () => {
  const { id } = useParams();
  const { user } = useUserStore();
  const navigate = useNavigate();

  const {
    data: meeting,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["meetings", id],
    queryFn: (): Promise<MeetingData> =>
      fetch(`/api/meetings/${id}`, {
        method: "GET",
        headers: {
          "LOGGED-USER-ID": user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !meeting) {
    return <p>Error fetching meeting data</p>;
  }

  const title = `${meeting.meeting_type ? `${formatMeetingType(meeting.meeting_type)} Meeting` : "Type"} - ${meeting.meeting_date ? formatDate(new Date(meeting.meeting_date)) : "Date"}`;
  const publicNotes = meeting.public_notes;
  const privateNotes = meeting.private_notes;
  const actionItems = meeting.action_items;
  const topics = meeting.topics;

  return (
    <div className="relative flex min-h-screen flex-col gap-y-5 rounded-lg px-5 py-5 text-white shadow-lg lg:px-10">
      {/* Header */}
      <div className="mb-4 flex w-full items-center justify-between">
        <Button
          disabled={!meeting.previous_meeting_id}
          className="group items-center bg-transparent p-2 font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
          onClick={() => navigate(`/meetings/${meeting.previous_meeting_id}`)}
        >
          <ArrowLeft className="h-8 w-8" />
          <span className="sr-only">Previous slide</span>
        </Button>
        <h1 className="text-center text-4xl font-bold">{title}</h1>
        <Button
          disabled={!meeting.next_meeting_id}
          className="group items-center bg-transparent p-2 font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
          onClick={() => navigate(`/meetings/${meeting.next_meeting_id}`)}
        >
          <ArrowRight className="h-6 w-6" />
          <span className="sr-only">Next slide</span>
        </Button>
      </div>

      {/* How I Feel */}
      <h1 className="mb-1 text-2xl font-bold">How I Feel</h1>
      {/* Placeholder radio buttons */}
      <div className="mb-1 flex flex-col items-center justify-center">
        <div className="feelings-options flex gap-8">
          <label className="flex cursor-pointer items-center">
            <input
              type="radio"
              name="feeling"
              value="1"
              className="focused:bg-red-500 h-6 w-6 appearance-none rounded-full border-2 border-gray-400 checked:bg-red-500"
            />
          </label>
          <label className="flex cursor-pointer items-center">
            <input
              type="radio"
              name="feeling"
              value="2"
              className="h-6 w-6 appearance-none rounded-full border-2 border-gray-400 checked:bg-yellow-500"
            />
          </label>
          <label className="flex cursor-pointer items-center">
            <input
              type="radio"
              name="feeling"
              value="3"
              className="h-6 w-6 appearance-none rounded-full border-2 border-gray-400 checked:bg-green-500"
            />
          </label>
        </div>
      </div>

      {/* Topics */}
      <div className="mb-1">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Topics</h1>
          <Button className="group items-center gap-x-3 rounded-md bg-transparent p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0">
            + Add Topic
          </Button>
        </div>
        {topics.length > 0 ? (
          <div className="px-10">
            <TopicCarousel topics={topics} />
          </div>
        ) : (
          <div>No topics to display</div>
        )}
      </div>

      {/* Action Items */}
      <div className="mb-1">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Action Items</h1>
          <Button className="group items-center gap-x-3 rounded-md bg-transparent p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0">
            + Add Action Item
          </Button>
        </div>
        {actionItems?.length ? (
          <div className="px-10">
            <ActionItemCarousel actionItems={actionItems} />
          </div>
        ) : (
          <div>No action items to display</div>
        )}
      </div>

      {/* Public Notes */}
      <div>
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Public Notes</h1>
          <Button
            className="group items-center gap-x-3 rounded-md bg-transparent p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
            onClick={() =>
              navigate(`/notes/new?private=false&meeting=${meeting.id}`)
            }
          >
            + Add Public Note
          </Button>
        </div>
        {publicNotes?.length ? (
          <div className="px-10">
            <NoteCarousel notes={publicNotes} />
          </div>
        ) : (
          <div>No public notes to display</div>
        )}
      </div>
      {/* Private Notes */}
      <div className="pb-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Private Notes</h1>
          <Button
            className="group items-center gap-x-3 rounded-md bg-transparent p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
            onClick={() =>
              navigate(`/notes/new?private=true&meeting=${meeting.id}`)
            }
          >
            + Add Private Note
          </Button>
        </div>
        {privateNotes?.length ? (
          <div className="px-10">
            <NoteCarousel notes={privateNotes} />
          </div>
        ) : (
          <div>No private notes to display</div>
        )}
      </div>
      {/* Footer */}
      <div className="flex justify-center gap-2">
        {/* Dialog for Updating a Meeting */}
        <Dialog>
          <DialogTrigger asChild>
            <Button className="rounded bg-gray-800 px-6 py-2 font-semibold text-white transition duration-300 hover:bg-gray-700 focus:ring-0">
              Update
            </Button>
          </DialogTrigger>
          <DialogContent className="border-0 bg-primary">
            <DialogHeader>
              <DialogTitle className="text-white">Update a meeting</DialogTitle>
            </DialogHeader>
          </DialogContent>
        </Dialog>
        {/* Dialog for Cancelling a Meeting */}
        <Dialog>
          <DialogTrigger asChild>
            <Button className="rounded bg-destructive px-6 py-2 font-semibold text-white transition duration-300 hover:bg-destructive focus:ring-0">
              Cancel
            </Button>
          </DialogTrigger>
          <DialogContent className="border-0 bg-primary">
            <DialogHeader>
              <DialogTitle className="text-white">
                Are you sure you want to cancel this meeting?
              </DialogTitle>
            </DialogHeader>
            <CancelMeetingForm userId={user!.id} meetingId={meeting.id} />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
