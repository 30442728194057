import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/ui";

interface TopicCardProps {
  name: string;
  creator_name: string;
  main_topic: boolean;
  questions: {
    id: number;
    question_text: string;
  }[];
}

const TopicCard = ({
  name,
  creator_name,
  main_topic,
  questions,
}: TopicCardProps) => {
  return (
    <Card className="flex h-80 w-full flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
      {/* Header section */}
      <CardHeader className="flex flex-col items-center bg-black/50 pb-2 pt-4">
        {/* Fixed height for the header */}
        <CardTitle className="text-white">{name}</CardTitle>
        <h1 className="text-sm text-gray-400"> by {creator_name}</h1>
        <h1
          className={`w-full text-center text-sm font-bold ${main_topic ? "text-green-400" : "text-purple-400"}`}
        >
          {main_topic ? "Main Topic" : "Sub Topic"}
        </h1>
      </CardHeader>
      {/* Content section */}
      <CardContent className="my-2 flex-1 overflow-y-auto">
        {/* Allow overflow */}
        <ul className="text-left">
          {questions.length > 0 ? (
            questions.map((question, index) => (
              <li key={index} className="mb-1 text-sm text-gray-300">
                {index + 1}. {question.question_text}
              </li>
            ))
          ) : (
            <p className="mb-1 text-sm text-gray-300">No questions assigned</p>
          )}
        </ul>
      </CardContent>

      {/* Footer section */}
      <CardFooter className="flex flex-col items-center pb-2">
        <Button className="group items-center rounded-md bg-transparent p-2 text-sm font-semibold text-gray-400 hover:bg-gray-900 hover:text-white focus:ring-0">
          + Add Question
        </Button>
      </CardFooter>
    </Card>
  );
};

export default TopicCard;
