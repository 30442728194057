import { Logo } from "@/components";

interface ErrorBoundaryFallbackProps {
  message: string;
}

export const ErrorBoundaryFallback = ({
  message,
}: ErrorBoundaryFallbackProps) => {
  return (
    <main className="flex h-screen flex-col items-center justify-center bg-slate-800">
      <div className="mb-20">
        <Logo />
      </div>
      <h2 className="mb-10 text-2xl">{message}</h2>
    </main>
  );
};
