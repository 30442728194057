import type { Employee } from "@/types/employee";
import type { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";

interface UserData extends Employee {
  notes: MeetingNote[];
  meetings: Meeting[];
}

export const combineNotesAndMeetings = (
  userData: UserData,
): [MeetingNote, Meeting | undefined][] => {
  if (!userData) return [];

  const meetingsMap = new Map<number, Meeting>();

  userData.meetings.forEach((meeting) => {
    meetingsMap.set(meeting.id, meeting);
  });

  const notesWithMeetings: [MeetingNote, Meeting | undefined][] =
    userData.notes.map((note) => {
      const associatedMeeting = meetingsMap.get(note.meeting_id);

      return [note, associatedMeeting] as [MeetingNote, Meeting | undefined];
    });

  return notesWithMeetings;
};
