import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import type { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { NotesProfileCard } from "../cards/NotesProfileCard";

interface NotesCarouselProps {
  notes: [MeetingNote, Meeting | undefined][];
}

export const NotesCarousel = ({ notes }: NotesCarouselProps) => {
  return (
    <div className="p-5">
      {notes.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No notes</p>
        </div>
      ) : (
        <Carousel opts={{ loop: false }}>
          <CarouselContent className="flex gap-3">
            {notes.map(([note, meeting]) => (
              <CarouselItem
                onClick={() => (window.location.href = `/notes/${note.id}`)}
                key={note.id}
                className="h-[350px] w-[300px] max-w-sm flex-shrink-0 flex-grow-0 basis-1/3"
              >
                <NotesProfileCard
                  id={note.id}
                  type={formatMeetingType(meeting!.meeting_type)}
                  writer={note.writer}
                  description={note.description}
                  private_meeting={note.private}
                  created_at={note.created_at}
                  updated_at={note.updated_at}
                  meeting={meeting!}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
