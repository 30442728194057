import type { Employee } from "./employee";

export enum MeetingType {
  oneOnOne = "1:1",
  feedback = "Feedback",
  performance_review = "Performance review",
}

export interface Meeting {
  id: number;
  meeting_type: MeetingType;
  meeting_date: Date;
  relative_time: string;
  target: string;
  location: string;
  participants: Employee[];
}
