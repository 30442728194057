import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Note } from "@/types/note";
import MeetingNoteCard from "../cards/MeetingNoteCard";

interface NoteCarouselProps {
  notes: Note[];
}

const NoteCarousel = ({ notes }: NoteCarouselProps) => {
  return (
    <Carousel
      className="relative mx-auto w-full px-0 py-5 lg:px-5"
      opts={{ loop: false }}
    >
      <CarouselPrevious />
      <CarouselContent className="flex">
        {notes.map((note) => (
          <CarouselItem
            key={note.id}
            className="sm:basis-1/2 lg:basis-1/3 xl:basis-1/5"
          >
            <MeetingNoteCard
              id={note.id}
              writer={note.writer}
              description={note.description}
              private_note={note.private}
              created_at={note.created_at}
              updated_at={note.updated_at}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselNext />
    </Carousel>
  );
};
export default NoteCarousel;
