import type { Meeting } from "@/types/meeting";
import type { MeetingNote } from "@/types/meeting_note";

export const splitNotesByVisibility = (
  notesWithMeetings: [MeetingNote, Meeting | undefined][],
): [
  [MeetingNote, Meeting | undefined][],
  [MeetingNote, Meeting | undefined][],
] => {
  if (!notesWithMeetings || notesWithMeetings.length === 0) return [[], []];

  const publicNotes: [MeetingNote, Meeting | undefined][] = [];
  const privateNotes: [MeetingNote, Meeting | undefined][] = [];

  notesWithMeetings.forEach(([note, meeting]) => {
    if (note.private) {
      privateNotes.push([note, meeting]);
    } else {
      publicNotes.push([note, meeting]);
    }
  });

  return [publicNotes, privateNotes];
};
