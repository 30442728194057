import { useQuery } from "@tanstack/react-query";

import { NewMeetingForm } from "@/components/forms/NewMeetingForm";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import { Employee } from "@/types/employee";

export const CreateMeetingScreen = () => {
  const { user } = useUserStore();

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
  } = useQuery({
    queryFn: (): Promise<Employee[]> =>
      fetch("/api/users", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  const email = user!.email;

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    isError: isErrorEmployees,
  } = useQuery({
    queryFn: (): Promise<Employee[]> =>
      fetch(`/api/users/${email}/employees`)
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["employees"],
  });

  if (isLoadingEmployees || isLoadingUsers) {
    return <LoadingScreen />;
  }

  if (isErrorEmployees || isErrorUsers) {
    return <div>Error: error fetching</div>;
  }

  if (!users?.length || !employees?.length) {
    return <div>No employees found</div>;
  }

  return (
    <div className="space-y-5 p-8 md:p-24">
      <NewMeetingForm targets={employees} participants={users} />
    </div>
  );
};
