import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { Layout } from "@/layout";
import { Home, NotFound } from "@/screens";
import { EmployeeList } from "@/screens/employees";
import { EmployeeDetails } from "@/screens/employees/id";
import { Login } from "@/screens/Login";
import { MeetingDetails } from "@/screens/meetings/id";
import { Meetings } from "@/screens/meetings/index";
import { CreateMeetingScreen } from "@/screens/meetings/new";
import { NoteDetails } from "@/screens/notes/id";
import { Notes } from "@/screens/notes/index";
import CreateNoteScreen from "@/screens/notes/new";
import { ModalRouter } from "./ModalRouter";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <>
      {/* PUBLIC ONLY ROUTES */}
      <Routes location={previousLocation ?? location}>
        <Route element={<ProtectedRoute expected="signedOut" />}>
          <Route element={<Layout />}>
            <Route element={<Login />} path={ROUTES.login} />
          </Route>
        </Route>

        {/* PRIVATE ONLY ROUTES */}
        <Route element={<ProtectedRoute expected="signedIn" />}>
          <Route element={<Layout />}>
            <Route element={<Navigate to={ROUTES.home} />} path={ROUTES.base} />
            <Route element={<Home />} path={ROUTES.home} />
            <Route element={<NoteDetails />} path={ROUTES.noteId} />
            <Route element={<CreateNoteScreen />} path={ROUTES.createNote} />
            <Route element={<EmployeeList />} path={ROUTES.employeeList} />
            <Route element={<EmployeeDetails />} path={ROUTES.employeeId} />
            <Route
              element={<CreateMeetingScreen />}
              path={ROUTES.createMeeting}
            />
            <Route path={ROUTES.notFound} element={<NotFound />} />
            <Route element={<Meetings />} path={ROUTES.meetings} />
            <Route element={<Notes />} path={ROUTES.notes} />
            <Route element={<MeetingDetails />} path={ROUTES.meetingId} />
          </Route>
        </Route>
      </Routes>

      {/* MODALS ROUTES */}
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
